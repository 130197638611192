import React from 'react';
import {Animated, View, StyleSheet} from 'react-native';
import Text from '@components/basic/text';
import DatePicker from '@/components/basic/date-picker';
import theme from '@/style';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {BasicObject} from '@/types';
import {formatDate, useResponsiveDimensions} from '@/utils';
import Icons from './components/icons';

const BetsTab = (props: {
  active: number;
  changeActive: (i: number) => void;
  filterValue?: string;
  onChangeFilter: (v: string) => void;
  currentDate?: Date;
  onSelectDate?: (date: Date) => void;
  menu: BasicObject[];
  transAnimate: Animated.Value;
}) => {
  const {
    active = 0,
    menu = [],
    changeActive,
    currentDate = new Date(),
    onSelectDate,
  } = props;

  const fadeAnim = React.useRef(new Animated.Value(0)).current;
  const saveWidth = React.useRef<number[]>([]);
  const [tabItemWidth, setTabItemWidth] = React.useState<number[]>([]);
  const [show, setShow] = React.useState(false);
  const {width} = useResponsiveDimensions();
  const inputRange = React.useMemo(() => {
    if (menu.length > 0) {
      return new Array(menu.length).fill(0).map((_, index) => index);
    }
    return [];
  }, [menu]);

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: active,
      duration: 200,
      useNativeDriver: true,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const saveItemWidth = (index: number, itemWidth: number) => {
    const copy = [...tabItemWidth];
    copy[index] = itemWidth;
    setTabItemWidth(copy);
  };

  const outputRange = React.useMemo(() => {
    const left = width - 24 - 80 - 28;
    if (saveWidth.current.length > 0) {
      const totalWidth = saveWidth.current.reduce((t, c) => t + c);
      const gap = (left - totalWidth) / 3;
      return saveWidth.current.map((item, index) => {
        const current = (item - 20) / 2;
        if (index === 0) {
          return current + 12;
        } else {
          const prev = saveWidth.current.slice(0, index);
          const totalPrev = prev.reduce((t, c) => t + c);
          return totalPrev + current + 12 + index * gap;
        }
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabItemWidth]);

  return (
    <div className="bg-card">
      <View
        style={[theme.flex.row, theme.flex.centerByCol, styles.tabContainer]}>
        <View
          style={[
            theme.flex.row,
            theme.flex.centerByCol,
            theme.flex.flex1,
            theme.padding.lrl,
            theme.padding.tbxxs,
            theme.flex.between,
          ]}>
          {menu.map((item, index) => (
            <NativeTouchableOpacity
              key={index}
              onPress={() => changeActive(index)}
              onLayout={e => {
                saveWidth.current[index] = e.nativeEvent.layout.width;
                saveItemWidth(index, e.nativeEvent.layout.width);
              }}>
              <div
                className={`text-sm ${
                  active === index ? 'text-t1 font-bold' : 'text-t2'
                }`}>
                {item.title}
              </div>
            </NativeTouchableOpacity>
          ))}
          {inputRange.length === outputRange.length && (
            <Animated.View
              style={[
                styles.indicatorStyle,
                {
                  transform: [
                    {
                      translateX: fadeAnim.interpolate({
                        inputRange: inputRange,
                        outputRange: outputRange,
                      }),
                    },
                  ],
                },
              ]}
            />
          )}
        </View>
        <DatePicker
          open={show}
          setOpen={setShow}
          value={currentDate}
          onValueChange={onSelectDate}
          type="month"
          titleRender={
            <NativeTouchableOpacity
              onPress={() => setShow(true)}
              style={styles.date}>
              <Icons.Calendar />
              <View style={[theme.padding.lrxxs]}>
                <Text fontSize={10} numberOfLines={1}>
                  {formatDate(currentDate, 'MM/yy')}
                </Text>
              </View>
              <Icons.Triangle />
            </NativeTouchableOpacity>
          }
        />
      </View>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {},
  indicatorStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 4,
    width: 20,
    backgroundColor: theme.basicColor.primary,
  },
  date: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 18,
    marginRight: 10,
    borderColor: 'var(--game-line)',
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    width: 80,
    backgroundColor: '#3B3F49',
  },
  tabContainer: {
    paddingTop: 10,
    paddingBottom: 8,
    borderBottomWidth: 1,
  },
});

export default BetsTab;
