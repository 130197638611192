import theme from '@/style';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import tableStyle from '../style';
import Text from '@/components/basic/text';
import ListItem from './list-item';
import {LotteryOrderTicket} from '@/common-pages/mix-lottery/mix-lottery-service';

const LotteryTable = ({
  list = [],
  isOpen = false,
  isQuick,
}: {
  list: LotteryOrderTicket[];
  isOpen?: boolean;
  isQuick?: boolean;
}) => {
  const {i18n} = useTranslation();
  return (
    <>
      <View style={[tableStyle.th]}>
        <View style={[theme.flex.flex1]}>
          <Text color="#fff">
            {i18n.t('bets-detail.label.number').toUpperCase()}
          </Text>
        </View>
        <View style={[theme.flex.flex1]}>
          <Text color="#fff" style={[theme.font.center]}>
            {i18n.t('bets-detail.label.payment').toUpperCase()}
          </Text>
        </View>
        <View style={[theme.flex.flex1]}>
          <Text color="#fff" style={[tableStyle.textRight]}>
            {i18n.t('bets-detail.label.result').toUpperCase()}
          </Text>
        </View>
      </View>
      <View>
        {list.map((item, index) => (
          <ListItem
            {...item}
            key={index}
            index={index}
            isOpen={isOpen}
            isQuick={isQuick}
          />
        ))}
      </View>
    </>
  );
};

export default LotteryTable;
