import {BasicObject} from '@/types';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import Ball from '@/common-pages/color/components/ball';
import {ALL_BALLS} from '@/common-pages/color/constant';

const ColorResult = ({info = {}}: {info: BasicObject}) => {
  const {i18n} = useTranslation();
  const color = !isNaN(Number(info.selectValue))
    ? ALL_BALLS.find(pie => pie.digit === info.selectValue)?.color
    : info.selectValue;
  // const Ball = React.useMemo(() => {
  //   if (info.colorResult) {
  //     const bg = info.colorResult.split(',').join('');
  //     return (
  //       <LazyImageBackground
  //         occupancy="transparent"
  //         width={32}
  //         height={32}
  //         style={[theme.flex.center]}
  //         imageUrl={COLORS[bg]}>
  //         <Text
  //           fontSize={16}
  //           color={theme.basicColor.white}
  //           fontFamily="fontDin"
  //           fontWeight="bold">
  //           {info.digitResult}
  //         </Text>
  //       </LazyImageBackground>
  //     );
  //   }
  //   return null;
  // }, [info]);

  return (
    <View style={[theme.flex.row, theme.padding.tbl, styles.container]}>
      <View style={[theme.flex.flex1]}>
        <Text color="#fff" size="medium" fontFamily="fontInterBold">
          {info.groupOrderNo}
        </Text>
        <Text color="#fff" size="medium" fontFamily="fontInterBold">
          {i18n.t('bets-detail.label.drawRes')}:
        </Text>
      </View>
      <View style={[theme.flex.flex1, styles.result]}>
        <Ball
          onlyColor={isNaN(Number(info.selectValue))}
          digit={info.selectValue}
          color={color}
          ballSize={28}
          fontColor={!isNaN(Number(info.selectValue)) ? '#000' : '#fff'}
          fontSize={isNaN(Number(info.selectValue)) ? 6 : 14}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    backgroundColor: '#282B32',
    marginBottom: 12,
  },
  result: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#505C6B',
    backgroundColor: '#0B0C0E',
  },
});

export default ColorResult;
