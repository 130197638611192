import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {copyText, goBack} from '@/utils';
import {useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {View, ScrollView} from 'react-native';
import {BasicObject, SafeAny} from '@/types';
import globalStore from '@/services/global.state';
import TableTitle from './components/table-title';
import KeralaTable from './components/kerala/table';
import DiceResult from './components/dice/dice-result';
import DiceTable from './components/dice/table';
import ScratchTable from './components/scratch/table';
import SattaTable from './components/satta/table';
import DigitTable from './components/digit/table';
import DigitResult from './components/digit/digit-result';
import ColorTable from './components/color/table';
import ColorResult from './components/color/color-result';
import KeralaResult from './components/kerala/kerala-result';
import SataResult from './components/satta/satta-result';
import Drawer from '@/components/basic/drawer';
import SharePanel from '@/components/business/share-panel/share-panel';
import {DrawerRef} from '@/components/basic/drawer/drawer';
import {shareToChat, shareToThird} from '../bets/bets.service';
// import Spin from '@/components/basic/spin';
// import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import StateLotteryResult from './components/state-lottery/result';
import BetsDetailCard from './bets-detail-card';
import dayjs from 'dayjs';
import LotteryTable from './components/state-lottery/table';
// import {toPriceStr} from '@/utils';
import {IShareInfo} from '../bets/bets.service';
import {useShareToast} from '../hooks/shareToast.hooks';
import {getUUID, transfor} from '@/utils';
import {TGameCode} from '@/services/global.service';
import {hasIM} from '@/config';

const map: {[key: string]: TGameCode} = {
  Kerala: 'kerala',
  '3 Digit': '3Digit',
  'Quick 3D': 'quick3d',
  Color: 'color',
  Dice: 'dice',
  'Satta Matka': 'matka',
  'Quick Race': 'quickRace',
  'Scratch off': 'scratch',
  Casino: 'casino',
  Live: 'live',
  Sports: 'sport',
  'State Lottery': 'stateLottery',
  'Quick State Lottery': 'quickStateLottery',
};

const BetsDetail = () => {
  const {shareSuccess, shareFail} = useShareToast();
  const {info, game} = useRoute().params as BasicObject;
  const [currentInfo, setCurrentInfo] = React.useState<BasicObject>(
    info ? JSON.parse(info) : {},
  );
  // const [loading, setLoading] = React.useState(false);
  const {i18n} = useTranslation();
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});

  const panelRef = React.useRef<DrawerRef>(null);
  const onCopy = (id: string) => {
    copyText(id);
    globalStore.globalSucessTotal(i18n.t('copy-success'));
  };

  const Result = React.useMemo(() => {
    switch (game) {
      case 'Kerala':
        return currentInfo.bonusStatus === 0 ? (
          <KeralaResult info={currentInfo} />
        ) : null;
      case 'Dice':
        return currentInfo.openStatus === 2 ? (
          <DiceResult info={currentInfo} />
        ) : null;
      case '3 Digit':
      case 'Quick 3D':
        return currentInfo.wonCode ? <DigitResult info={currentInfo} /> : null;
      case 'Color':
        return currentInfo.openStatus === 2 ? (
          <ColorResult info={currentInfo} />
        ) : null;
      case 'Satta Matka':
        return 1 ? <SataResult info={currentInfo} /> : null;
      case 'State Lottery':
      case 'Quick State Lottery':
        return (
          <StateLotteryResult
            isQuick={game === 'Quick State Lottery'}
            name={currentInfo.lotteryName}
            drawTime={
              `${currentInfo.drawSec}`.length === 13
                ? currentInfo.drawSec
                : currentInfo.drawSec * 1000
            }
            num={currentInfo.roundNo}
            wonCode={currentInfo.wonCode}
          />
        );
      default:
        return null;
    }
  }, [game, currentInfo]);

  const Table = React.useMemo(() => {
    switch (game) {
      case 'Kerala':
        return (
          <KeralaTable
            list={currentInfo.userWonList}
            isOpen={currentInfo.bonusStatus === 0}
          />
        );
      case '3 Digit':
      case 'Quick 3D':
        return (
          <DigitTable
            list={currentInfo.codeLists}
            isOpen={currentInfo.wonCode && !currentInfo.wonCode.startsWith('*')}
          />
        );
      case 'Color':
        return (
          <ColorTable
            list={currentInfo.subsetList}
            isOpen={currentInfo.openStatus !== 1}
          />
        );
      case 'Dice':
        return (
          <DiceTable
            list={currentInfo.typeList}
            isOpen={currentInfo.openStatus !== 1}
          />
        );
      case 'Satta Matka':
        return (
          <SattaTable
            list={currentInfo.userDigits}
            isOpen={currentInfo.openStatus !== 1}
          />
        );
      case 'Scratch off':
        return (
          <ScratchTable
            list={currentInfo.orderList}
            payment={currentInfo.orderPrice}
          />
        );
      case 'State Lottery':
      case 'Quick State Lottery':
        return (
          <LotteryTable
            isQuick={game === 'Quick State Lottery'}
            list={currentInfo.ticketsLists}
            isOpen={currentInfo.wonCode.every(
              (v: string) => v.indexOf('-') === -1,
            )}
          />
        );
      default:
        return null;
    }
  }, [game, currentInfo]);

  const status = React.useMemo(() => {
    const {shareGameDto = {}} = currentInfo || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      // Kerala
      if (currentInfo.bonusStatus === 0) {
        return currentInfo.wonAmount > 0 ? 1 : 0;
      }
      return currentInfo.bonusStatus;
    }
    if (gameCode === '3Digit' || gameCode === 'pick3') {
      // 3 Digit
      if (currentInfo.wonCode) {
        // 已开奖
        return currentInfo.winAmount > 0 ? 1 : 0;
      }
      return 2;
    }
    if (gameCode === 'quick 3digit') {
      if (currentInfo.wonCode) {
        // 已开奖
        return currentInfo.winAmount > 0 ? 1 : 0;
      }
      return 2;
    }
    if (gameCode === 'color') {
      // color
      if (currentInfo.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return currentInfo.awardAmount > 0 ? 1 : 0;
      }
    }
    if (gameCode === 'matka') {
      if (currentInfo.openStatus === 1) {
        return 2;
      }
      return currentInfo.wonAmount > 0 ? 1 : 0;
    }
    if (gameCode === 'dice') {
      // dice
      if (currentInfo.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return currentInfo.totalAwardAmount > 0 ? 1 : 0;
      }
    }
    if (
      currentInfo.orderStatus !== undefined &&
      currentInfo.openStatus !== undefined
    ) {
      // 1=中奖 0=未中奖 2=未使用
      // scratch
      if (currentInfo.openStatus === 2) {
        // 未开奖
        return 2;
      }
      return currentInfo.orderStatus;
    }
    return currentInfo.orderStatus;
  }, [currentInfo]);

  const hasShare = React.useMemo(() => {
    if (currentInfo?.shareGameDto?.gameCode === 'lottery official india') {
      return currentInfo?.status === 0 || currentInfo?.status === 1;
    }
    if (currentInfo?.shareGameDto?.gameCode === 'kerala') {
      return status === 1;
    }
    return status === 1 || status === 2;
  }, [currentInfo, status]);

  const getShareInfo = () => {
    const user = localStorage.getItem('user') || '{}';
    const userId = JSON.parse(user).userId;
    setThreeOrderData({
      userId,
      data: transfor({...currentInfo, userId}, map[game], {
        from: 'order',
      }),
    });
  };

  useEffect(getShareInfo, [game, currentInfo]);

  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}>
      <DetailNavTitle
        onBack={goBack}
        serverRight
        hideAmount
        title={i18n.t('bets-detail.label.orderDetail')}
      />
      <View style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView stickyHeaderIndices={[Result ? 2 : 1]}>
          <BetsDetailCard onCopy={onCopy} info={currentInfo} game={game} />
          {Result}
          <TableTitle
            tip={
              game === 'State Lottery' || game === 'Quick State Lottery'
                ? `${i18n.t('bets-page.label.bettingTime')}:${dayjs(
                    info.createTime,
                  ).format('DD-MM-YYYY HH:mm')}`
                : ''
            }
            award={Boolean(currentInfo?.shareAward)}
            hasShare={hasShare && hasIM}
            onShare={() => panelRef.current?.open()}
          />
          <View
            style={[
              theme.margin.lrl,
              theme.margin.btml,
              theme.borderRadius.m,
              theme.overflow.hidden,
            ]}>
            {Table}
          </View>
        </ScrollView>
      </View>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType={map[game]}
          hasReward={Boolean(currentInfo.shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              {
                gameCode: map[game],
                orderNo: getUUID(),
                threeOrderData,
              },
              (infos: IShareInfo) => shareToChat(infos),
              () => {
                shareSuccess(currentInfo.shareAward);
                setCurrentInfo({...currentInfo, shareAward: 0});
              },
              shareFail,
            );
          }}
        />
      </Drawer>
    </View>
  );
};

export default BetsDetail;
