import {View, StyleSheet} from 'react-native';
import tableStyle from '../style';
import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import {BasicObject} from '@/types';
import {toPriceStr} from '@/utils';
import {LazyImageBackground} from '@/components/basic/image';
import {DIGIT} from '../constant';
import {useTranslation} from 'react-i18next';

const DigitTable = ({
  list = [],
  isOpen,
}: {
  list: BasicObject[];
  isOpen?: boolean;
}) => {
  const {i18n} = useTranslation();
  const getABC = (indexCode: string) => {
    if (!indexCode) {
      return ['A', 'B', 'C'].map(key => ({key, value: ''}));
    }
    const [alphaCodes, digitCodes] = indexCode.split('=').map(v => v.split(''));
    const codeMap = alphaCodes.reduce((a, b, i) => {
      a[b] = digitCodes[i];
      return a;
    }, {} as BasicObject);
    const result = ['A', 'B', 'C'].map(key => ({
      key,
      value: codeMap[key] || '',
    }));
    return result;
  };

  return (
    <>
      <View style={[tableStyle.th]}>
        <View style={[theme.flex.flex1]}>
          <View style={[theme.flex.row, styles.container]}>
            {['A', 'B', 'C'].map((item, index) => (
              <LazyImageBackground
                key={index}
                occupancy="transparent"
                style={[theme.flex.center]}
                width={24}
                height={24}
                imageUrl={DIGIT[item]}>
                <Text fontFamily="fontInterBold" color={'black'}>
                  {item}
                </Text>
              </LazyImageBackground>
            ))}
          </View>
        </View>
        <View style={[tableStyle.tbPayment]}>
          <Text style={[theme.font.center]}>
            {i18n.t('bets-detail.label.payment').toUpperCase()}
          </Text>
        </View>
        <View style={[theme.flex.flex1]}>
          <Text style={[tableStyle.textRight]}>
            {i18n.t('bets-detail.label.result').toUpperCase()}
          </Text>
        </View>
      </View>
      <View>
        {list.map((item, index) => {
          const abcData = getABC(item.indexCode);
          return (
            <View
              key={index}
              style={[tableStyle.td, index % 2 === 1 && tableStyle.tdGray]}>
              <View style={[theme.flex.flex1, theme.flex.row]}>
                <View
                  style={[
                    theme.flex.row,
                    theme.flex.centerByCol,
                    styles.container,
                  ]}>
                  {abcData.map((pie, i) => (
                    <LazyImageBackground
                      occupancy="transparent"
                      key={i}
                      style={[theme.flex.center]}
                      width={24}
                      height={24}
                      imageUrl={DIGIT[pie.key]}>
                      <Text fontFamily="fontInterBold" color={'black'}>
                        {pie.value}
                      </Text>
                    </LazyImageBackground>
                  ))}
                </View>
              </View>
              <View style={[tableStyle.tbPayment]}>
                <Text
                  blod
                  fontFamily="fontInter"
                  size="medium"
                  style={[theme.font.center]}>
                  {toPriceStr(item.pickAmount, {
                    fixed: 2,
                    showCurrency: true,
                    thousands: true,
                  })}
                  *{item.pickCount}
                </Text>
              </View>
              <View style={[theme.flex.flex1]}>
                <View
                  style={[
                    theme.flex.end,
                    theme.flex.row,
                    theme.flex.centerByCol,
                  ]}>
                  <View style={[theme.margin.leftxxs, theme.flex.alignEnd]}>
                    <Text>
                      {i18n.t(
                        !isOpen
                          ? 'bets-detail.label.noDraw'
                          : (item.codeWinAmount || item.winAmount) > 0
                          ? 'bets-detail.label.won'
                          : 'bets-detail.label.noWin',
                      )}
                    </Text>
                    <Text
                      color={
                        (item.codeWinAmount || item.winAmount) > 0
                          ? theme.backgroundColor.orange
                          : theme.fontColor.second
                      }
                      fontFamily="fontInter"
                      blod
                      size="medium">
                      {!isOpen
                        ? '...'
                        : toPriceStr(
                            item.codeWinAmount || item.winAmount || 0,
                            {
                              fixed: 2,
                              showCurrency: true,
                              thousands: true,
                            },
                          )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    columnGap: 2,
  },
});

export default DigitTable;
