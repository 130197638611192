import ShareButton from '@/components/business/bets/share-btn';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';

const TableTitle = ({
  title = '',
  hasShare = false,
  tip,
  onShare,
  award = false,
}: {
  title?: string;
  hasShare?: boolean;
  tip?: string;
  onShare?: () => void;
  award?: boolean;
}) => {
  const {i18n} = useTranslation();
  return (
    <View style={[theme.background.lightGrey]}>
      <LinearGradient style={styles.container} colors={['#212329', '#16181B']}>
        <View
          style={[
            theme.flex.row,
            theme.padding.l,
            theme.flex.centerByCol,
            theme.flex.between,
          ]}>
          <View>
            <Text size="medium" blod color="#fff">
              {title || i18n.t('me.bottom.myBets')}
            </Text>
            {!!tip && (
              <Text accent fontWeight="500">
                {tip}
              </Text>
            )}
          </View>
          {hasShare && <ShareButton onShare={onShare} hasAward={award} />}
        </View>
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
});

export default TableTitle;
