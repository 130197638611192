import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {debounce, goBack} from '@/utils';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Animated, View} from 'react-native';
import BetsList from './bets-list';
import BetsTab from './bets-tab-new';
import {TabView} from '@rneui/themed';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';
import {packageId} from '@/config';
import BetsFilter from './bets-filter-new';

const MENU = [
  {
    title: 'ALL',
    value: 'ALL',
  },
  {
    title: 'To be drawn',
    value: 'UNKNOWN',
  },
  {
    title: 'Drawn',
    value: 'KNOWN',
  },
  {
    title: 'Won',
    value: 'WON',
  },
];

const Bets = () => {
  const {tag} = (useRoute().params as BasicObject) || {};
  const {i18n} = useTranslation();
  const [active, setActive] = React.useState(0);
  const [activeTag, setActiveTag] = React.useState(
    packageId === 7
      ? tag || 'Quick 3D'
      : packageId === 5
      ? tag || 'Color'
      : tag || 'Kerala',
  );
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [expend, setExpend] = React.useState(true);
  const transAnimate = React.useRef(new Animated.Value(0)).current;

  const changeStatus = debounce((status: boolean) => {
    setExpend(status);
  });

  React.useEffect(() => {
    transAnimate.addListener(e => {
      if (e.value > 3) {
        changeStatus(false);
        // setScrollEnable(false);
      } else {
        changeStatus(true);
      }
    });
    return () => {
      transAnimate.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    transAnimate.setValue(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  return (
    <View style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}>
      <DetailNavTitle
        onBack={goBack}
        serverRight
        hideAmount
        title={i18n.t('me.bottom.myBets')}
        containerStyle={{
          // @ts-ignore
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.20)',
        }}
      />
      <BetsTab
        transAnimate={transAnimate}
        currentDate={currentDate}
        onSelectDate={setCurrentDate}
        filterValue={activeTag}
        menu={MENU}
        active={active}
        changeActive={setActive}
        onChangeFilter={setActiveTag}
      />
      <BetsFilter
        onChange={setActiveTag}
        value={activeTag}
        expend={expend}
        onExpend={() => setExpend(!expend)}
      />
      <TabView
        containerStyle={[theme.overflow.hidden, theme.flex.flex1]}
        value={active}
        onChange={setActive}
        animationConfig={{
          duration: 200,
          useNativeDriver: true,
        }}
        animationType="timing"
        disableTransition>
        {MENU.map((item, index) => {
          return (
            <TabView.Item key={index} style={[theme.flex.flex1]}>
              <BetsList
                transAnimate={transAnimate}
                isActive={active === index}
                game={activeTag}
                status={item.value}
                currentDate={currentDate}
              />
            </TabView.Item>
          );
        })}
      </TabView>
    </View>
  );
};

export default Bets;
