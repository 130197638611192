import {downIcon} from '@/common-pages/rebate/rebate.variables';
import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import {toPriceStr} from '@/utils';
import dayjs from 'dayjs';
import React from 'react';
import {View, LayoutAnimation} from 'react-native';
import FeeDetail from '../fee';
import tableStyle from '../style';
import {BasicObject} from '@/types';
import Text from '@/components/basic/text';
import {useTranslation} from 'react-i18next';
import Ball from '@/common-pages/color/components/ball';
import {ALL_BALLS} from '@/common-pages/color/constant';

const TableListItem = ({
  item,
  index = 0,
  isOpen,
}: {
  item: BasicObject;
  index: number;
  isOpen?: boolean;
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const {i18n} = useTranslation();
  const arrowStyle = React.useMemo(() => {
    return {
      transform: [
        {
          rotate: showMore ? '0deg' : '-90deg',
        },
      ],
    };
  }, [showMore]);

  const color = !isNaN(Number(item.selectValue))
    ? ALL_BALLS.find(pie => pie.digit === item.selectValue)?.color
    : item.selectValue;

  return (
    <View>
      <View
        style={[
          tableStyle.td,
          theme.flex.centerByCol,
          {
            backgroundColor: index % 2 === 1 ? '#3B3F49' : '#31343D',
          },
        ]}>
        <View style={[theme.flex.flex1]}>
          <Ball
            onlyColor={isNaN(Number(item.selectValue))}
            digit={item.selectValue}
            color={color}
            ballSize={28}
            fontColor={!isNaN(Number(item.selectValue)) ? '#000' : '#fff'}
            fontSize={isNaN(Number(item.selectValue)) ? 6 : 14}
          />
        </View>
        <View style={[tableStyle.tbPayment]}>
          <Text
            size="medium"
            fontFamily="fontInter"
            blod
            color="#fff"
            style={[theme.font.center]}>
            {toPriceStr(item.amount, {
              fixed: 2,
              showCurrency: true,
              thousands: true,
            })}
          </Text>
        </View>
        <View style={[theme.flex.flex1, theme.flex.row, theme.flex.end]}>
          <NativeTouchableOpacity
            onPress={() => {
              LayoutAnimation.configureNext(
                LayoutAnimation.Presets.easeInEaseOut,
              );
              setShowMore(!showMore);
            }}
            style={[theme.flex.row, theme.flex.centerByCol]}>
            <View style={[theme.flex.alignEnd]}>
              <Text color="#fff">
                {i18n.t(
                  !isOpen
                    ? 'bets-detail.label.noDraw'
                    : item.awardStatus === 1
                    ? 'bets-detail.label.won'
                    : 'bets-detail.label.noWin',
                )}
              </Text>
              <Text
                color={
                  item.awardStatus === 1 ? theme.backgroundColor.orange : '#fff'
                }
                size="medium"
                fontFamily="fontInter"
                blod>
                {!isOpen
                  ? '...'
                  : toPriceStr(item.awardAmount, {
                      fixed: 2,
                      showCurrency: true,
                      thousands: true,
                    })}
              </Text>
            </View>
            <View style={[arrowStyle, theme.margin.leftxxs]}>
              <LazyImage
                imageUrl={downIcon}
                width={14}
                height={14}
                occupancy="transparent"
              />
            </View>
          </NativeTouchableOpacity>
        </View>
      </View>
      {showMore && (
        <FeeDetail
          index={index}
          delivery={item.deliveryAmount}
          fee={item.feeAmount}
          time={dayjs(item.createTime).format('DD/MM/YYYY HH:mm:ss')}
        />
      )}
    </View>
  );
};

export default TableListItem;
